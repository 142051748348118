import Script from "next/script";
import type { ReactNode } from "react";
import styled from "styled-components";
import { chatbotSettings, settings } from "@/configs/settings";
import Media from "@/styles/website/Media";

type Props = {
  children: ReactNode;
  mobileStyle?: {
    width?: string;
    height?: string;
    bottom?: string;
    right?: string;
  };
};

export default function ChatProvider({ children, mobileStyle = {} }: Props) {
  const initializeChatbot = () => {
    if (!Twc) return;
    if (typeof Twc !== "function") return;
    Twc("init", {
      brandKey: chatbotSettings.BRAND_KEY,
      channelType: chatbotSettings.CHANNEL_TYPE,
      scenarioId: chatbotSettings.SCENARIO_ID,
      buttonOption: {
        showLauncher: false
      }
    });
  };

  const onChatOpen = () => {
    if (typeof Twc === "object" && "Chat" in Twc) {
      if (
        typeof Twc.Chat === "object" &&
        "open" in Twc.Chat &&
        typeof Twc.Chat.open === "function"
      ) {
        Twc?.Chat?.open();
      }
    }
  };

  return (
    <StyledChatProvider>
      <Script
        src="https://public-common-sdk.s3.ap-northeast-2.amazonaws.com/sdk/cloud/Twc.plugin.js"
        onReady={initializeChatbot}
      ></Script>
      <StyleChatButton onClick={() => onChatOpen()} mobileStyle={mobileStyle}>
        <img
          alt="webchat"
          src={`${settings.CDN_DOMAIN}/etc/webchat.png`}
          srcSet={`${settings.CDN_DOMAIN}/etc/webchat.png 1x,
        ${settings.CDN_DOMAIN}/etc/webchat_2x.png 2x,
        ${settings.CDN_DOMAIN}/etc/webchat_3x.png 3x
        `}
        />
      </StyleChatButton>
      {children}
    </StyledChatProvider>
  );
}

type StyleChatButtonProps = {
  mobileStyle: Props["mobileStyle"];
  children: ReactNode;
  onClick: () => void;
};
const StyledChatProvider = styled.div`
  position: relative;
`;

const StyleChatButton = styled.a<StyleChatButtonProps>`
  img {
    width: 72px;
    height: 72px;
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 1;
    cursor: pointer;
    ${Media.mobile`
      width:${(props) => `${props.mobileStyle.width ?? "60px"}`};
      height: ${(props) => `${props.mobileStyle.height ?? "60px"}`};
      position: fixed;
      bottom: ${(props) => `${props.mobileStyle.bottom ?? "25px"}`};
      right: ${(props) => `${props.mobileStyle.right ?? "25px"}`};
    `}
  }
`;
